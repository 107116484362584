@font-face {
  font-family: 'Poppins';
  src: url('../public/Poppins-Regular.otf') format('opentype');
  /* You can specify additional font weights and styles here if needed */
}

@font-face {
  font-family: 'PermanentMarker';
  src: url('../public/PermanentMarker-Regular.otf') format('opentype');
  /* You can specify additional font weights and styles here if needed */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.centeredContainer {
  text-align: center;
  display: flex;
  align-content: center;
}

.jetskilogo {
  width: 300px;
  padding-top: 20px;
  padding-left: 2vw;
  vertical-align: middle;
}

.trixxImage {
  width: 80%;
  display: inline !important;
}

*:focus {
  outline: 0;
  outline: none;
}

section {
  position: flex;
  overflow: hidden;
}

section .wave {
  position: absolute;
  top: 710px;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background: url("../public/wave.png");
  background-size: 1200px 100px;
}

section .wave.wave1 {
  animation: animate 30s linear infinite alternate;
  z-index: 1000;
  opacity: 1;
  animation-delay: 0s;
}

section .wave.wave2 {
  animation: animate2 15s linear infinite alternate;
  z-index: 999;
  opacity: 0.5;
  animation-delay: -5s;
  bottom: 10px;
}

section .wave.wave3 {
  animation: animate 25s linear infinite alternate;
  z-index: 998;
  opacity: 0.2;
  animation-delay: -2s;
  bottom: 15;
}

section .wave.wave4 {
  animation: animate2 20s linear infinite alternate;
  z-index: 997;
  opacity: 0.7;
  animation-delay: -2s;
  bottom: 20px;
}

@media screen and (max-width: 900px) {
  section .wave {
    background-size: 750px 100px;
  }
}

@media screen and (max-width: 450px) {
  section .wave {
    background-size: 500px 100px;
  }

  .jetskilogo {
    width: 150px;
  }
}

@keyframes animate {
  0% {
    background-position-x: 0;
  }
  100% {
    background-position-x: 1000px;
  }
}

@keyframes animate2 {
  0% {
    background-position-x: 0;
  }
  100% {
    background-position-x: -1000px;
  }
}

.home {
  background-image: url('../public/pixelcut-export.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  height: 800px;
  background-position: bottom;
}




.bottomBackground {
  background-image: url('../public/haydenblend.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  height: 400px;
  background-position: top;
}

@media only screen and (min-width: 1000px) {
  .bottomBackground {
    height: 800px;
    /* Adjust the height for large screens */
  }
}

@media only screen and (max-width: 400px) {
  .headerText {
    font-size: 18px !important;
    /* Adjust the height for large screens */
  }
}


.aboutBackground {
  background-color: #D0DDE9;
}

.fade-in {
  opacity: 0;
  transition: opacity 1.5s ease;
}

.fade-in.is-visible {
  opacity: 1;
}